/* Home.css */

/* Default card styling */
.card {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Card hover effects */
  .card:hover {
    background-color: #e0f2f1; /* Light teal for hover effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* You can also add different hover colors for each card if needed */
  .card:nth-child(1):hover {
    background-color: #b2dfdb; /* Light teal */
  }
  
  .card:nth-child(2):hover {
    background-color: #80cbc4; /* Medium teal */
  }
  
  .card:nth-child(3):hover {
    background-color: #4db6a8; /* Darker teal */
  }
  /* Ensure the navbar has a higher z-index */
.navbar {
  z-index: 1000; /* Adjust this value as needed */
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add padding to the top of the main content to offset the fixed navbar */
.pt-24 {
  padding-top: 6rem; /* Adjust this value based on the navbar height */
}

/* Ensure the MapComponent and other sections have proper spacing */
.map-container {
  margin-top: 2rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .h-80 {
    height: 300px; /* Adjust height for smaller screens */
  }

  .text-2xl {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .text-xl {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }
}